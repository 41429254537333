import { createTheme } from '@mui/material';

import { PALETTE } from './palette';
import { defaultTheme } from './default.theme';

// eslint-disable-next-line import/prefer-default-export
export const dentoloTheme = createTheme(defaultTheme, {
  palette: {
    background: {
      button: PALETTE.PROFESSIONAL_BLUE,
      cancellationDate: PALETTE.VITALIZED_GREEN,
      footer: PALETTE.PROFESSIONAL_BLUE,
      info: PALETTE.CLEAR_LIGHT_BLUE,
      radio: PALETTE.VITALIZED_GREEN,
      tag: PALETTE.VITALIZED_GREEN,
      viewport: PALETTE.ULTRA_CLEAR_LIGHT_BLUE,
    },
    shadows: [
      defaultTheme.shadows[0],
      defaultTheme.shadows[1],
      defaultTheme.shadows[2],
      `0px 0px 0px 3px ${PALETTE.PROFESSIONAL_LIGHT_BLUE}`,
      `0 0 4px 1px ${PALETTE.VITALIZED_GREEN}`,
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    text: {
      button: PALETTE.VITALIZED_GREEN,
      copyright: PALETTE.WHITE,
      disabled: PALETTE.GRAY,
      footerLink: PALETTE.VITALIZED_GREEN,
      footerLinkHover: PALETTE.CLEAR_LIGHT_BLUE,
      subtext: PALETTE.PROFESSIONAL_LIGHT_BLUE,
    },
  },
});
