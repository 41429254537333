import { styled, css } from '@mui/material';
import { Container } from '../../common';

export const StyledPaymentNotificationContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const StyledFixedContainer = styled('div')(
  ({ theme, fixed }) => css`
    position: relative;

    ${fixed
      ? css`
          position: fixed;
          top: ${theme.shape.headerMobile};
          margin-top: 1px;
          left: 50%;
          width: ${theme.shape.container};
          transform: translateX(-50%);
          z-index: 2;
        `
      : ''}
  `
);

export const StyledPayNowLinkContainer = styled('div')(
  ({ theme, textAlign }) => css`
    margin-top: 0.25rem;
    text-align: ${textAlign || 'right'};

    > a {
      font-size: 1.185rem;
      font-weight: 700;
      border-bottom: 2px solid ${theme.palette.main.professional_blue};
      transition: border 0.2s ease;

      &:hover {
        border-bottom-color: transparent;
      }
    }
  `
);
