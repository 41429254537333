// eslint-disable-next-line import/prefer-default-export
export const PALETTE = {
  // Getolo Main colors
  PROFESSIONAL_BLUE: '#003264', // Texts, footer background, secondary button stroke and label
  PROFESSIONAL_LIGHT_BLUE: '#335B83', // Secondary texts, transparency drop-downs and supportive texts
  PRODUCT_BLUE: '#7C95AE', // Product placeholders only

  // Getolo Neutral colors
  GRAY: '#666666', // Supportive texts
  LIGHT_GRAY: '#e7e7e8', // Background areas
  ULTRA_LIGHT_GRAY: '#f7f7f7', // Background areas
  WHITE: '#ffffff', // Background areas

  // Getolo Additional colors
  ERROR_RED: '#DF2020',
  SUCCESS_GREEN: '#448000',

  // Dentolo Main colors
  VITALIZED_GREEN: '#32FF96', // Primary Button labels
  CLEAR_LIGHT_GREEN: '#EAFFF4', // Background areas

  // Dentolo Additional colors
  CLEAR_LIGHT_BLUE: '#DFF1FD', // Background areas
  ULTRA_CLEAR_LIGHT_BLUE: '#F3F9FE', // Background areas
  LOVELY_ROSE: '#FAC8D2', // Webpages only (used in icons)

  // Petolo Main colors
  VITALIZED_ORANGE: '#FFA445', // Buttons background color/ highlights

  // Petolo Additional colors
  CLEAR_LIGHT_ORANGE: '#FFF4D9', // Background areas/ Footer background

  // Vitolo Main colors
  VIBRANT_LIGHT_VIOLET: '#CBB7FF', // Texts, footer background, secondary button stroke and label

  // Vitolo Supporting colors
  LAVENDER_INDIGO: '#956CFF',
  CLEAR_LIGHT_VIOLET: '#F5F2FF', // Background areas
  ULTRA_CLEAR_LIGHT_VIOLET: '#FCFBFF', // Background areas
  DUSTY_BLUE: '#6675B1', // Background areas
  PERIWINKLE: '#D1CDFF', // Background areas

  // Vitolo Additional colors
  LIGHT_PEACH: '#FED5B7',
  ULTRA_LIGHT_PEACH: '#FEEADB',
  SPRING_GREEN: '#3AF396',
  LIGHT_SPRING_GREEN: '#A5FFD2',
};
