import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

export const StyledPromotionCarouselCard = styled('div')`
  width: 100%;
  padding: 1.125rem 1rem 0;

  @media (min-width: ${MIN_WIDTH_MD}) {
    padding-top: 2.5rem;
  }
`;

export const StyledDaDirektPromotionCarouselCard = styled(StyledPromotionCarouselCard)`
  padding-top: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    padding-top: 1.5rem;
  }
`;

export const StyledPromotionCarouselCardContent = styled('div')`
  display: flex;
  column-gap: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledPromotionCarouselCardContentImageWrapper = styled('div')`
  margin: 0 auto;
  width: auto;
  height: 3rem;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    height: 4.875rem;
  }
`;

export const StyledPromotionCarouselCardContentWrapper = styled('h4')(
  ({ theme }) => css`
    text-align: left;
    font-size: 18px;
    color: ${theme.palette.main.professional_blue};

    @media (min-width: ${MIN_WIDTH_MD}) {
      text-align: center;
      margin-top: 2rem;
      margin-left: 0;
      font-size: 20px;
    }
  `
);

export const StyledPromotionCarouselCardMegaContent = styled('span')`
  font-weight: 700;
`;

export const StyledPromotionCarouselCardNormalContent = styled('span')`
  font-weight: 300;
`;

export const StyledPromotionCarouselCardContentTitle = styled('h4')(
  ({ theme }) => css`
    font-size: 1rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1.063rem;
    color: ${theme.palette.main.professional_blue};
    text-align: center;

    @media (min-width: ${MIN_WIDTH_MD}) {
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }
  `
);

export const StyledDaDirektPromotionCarouselCardContentTitle = styled(
  StyledPromotionCarouselCardContentTitle
)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1.625rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledPromotionCarouselButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
