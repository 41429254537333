import { css, styled } from '@mui/material';
import Slider from 'react-slick';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { Paper, Separator } from '../../../components/common';

export const promotionCarouselSliderHeight = {
  desktop: '18.75rem',
  mobile: '13.75rem',
};

export const promotionCarouselControlsHeight = {
  desktop: '3.375rem',
  mobile: '3.125rem',
};

export const StyledPromotionCarousel = styled(Paper)`
  padding: 0;
`;

export const StyledPromotionCarouselSlider = styled(Slider)`
  height: ${promotionCarouselSliderHeight.mobile};
  border-radius: 5px;
  clip-path: border-box;

  ${({ slideCount }) =>
    slideCount > 1 &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  @media (min-width: ${MIN_WIDTH_MD}) {
    height: ${promotionCarouselSliderHeight.desktop};
  }
`;

export const StyledPromotionCarouselControls = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${promotionCarouselControlsHeight.mobile};

  @media (min-width: ${MIN_WIDTH_MD}) {
    padding: 0 0.5rem;
    height: ${promotionCarouselControlsHeight.desktop};
  }
`;

export const StyledPromotionCarouselControlsDotsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
`;

export const StyledPromotionCarouselControlsDots = styled('div')(
  ({ theme, active }) => css`
    width: 0.5rem;
    height: 0.5rem;
    background-color: transparent;
    border: 1px solid ${theme.palette.main.professional_blue};
    border-radius: 50%;
    cursor: pointer;

    ${active &&
    css`
      background-color: ${theme.palette.main.professional_blue};
      pointer-events: none;
      cursor: default;
    `}
  `
);

export const StyledPromotionCarouselSeparator = styled(Separator)`
  margin: 0 1rem;
`;
