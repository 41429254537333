import { css, styled } from '@mui/material';

import { imgLinks } from '../../../shared';
import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';

import { Href } from '../../../components/ui';
import { PALETTE } from '../../../theme/palette';

export const StyledVitoloCrossSellingCard = styled('div')(
  ({ theme, imgDesktop, imgMobile }) => css`
    position: relative;
    padding: 1rem 1rem 0;
    width: 100%;
    height: ${promotionCarouselSliderHeight.mobile};
    background: linear-gradient(
      178deg,
      ${PALETTE.VIBRANT_LIGHT_VIOLET} 27.17%,
      #ded1ff 63.7%,
      ${PALETTE.CLEAR_LIGHT_VIOLET} 86.06%,
      ${theme.palette.common.white} 94.67%
    );

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 137px;
      height: 143px;
      background: url(${imgMobile}) no-repeat;
      background-size: 137px 143px;
      background-position: right bottom;
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      padding: 2rem 1.25rem 0;
      height: ${promotionCarouselSliderHeight.desktop};

      &::before {
        width: 296px;
        height: 300px;
        background-image: url(${imgDesktop});
        background-size: 296px 300px;
      }
    }
  `
);

export const StyledVitoloCrossSellingCardTitle = styled('div')(
  ({ theme }) => css`
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};

    > u {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 0;
      text-decoration: none;

      &::after {
        content: '';
        margin-left: -2.5rem;
        width: 43px;
        height: 4px;
        background: url(${imgLinks['crossselling.underline']}) no-repeat;
        background-size: 43px 4px;
      }
    }

    > span {
      color: ${theme.palette.common.white};
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      width: 330px;
      font-size: 1.25rem;

      > u {
        &::after {
          background-size: 60px 8px;
          width: 60px;
          height: 8px;
        }
      }
    }
  `
);

export const StyledVitoloCrossSellingCardText = styled('div')(
  ({ theme }) => css`
    margin-top: 0.75rem;
    width: 210px;
    font-size: 0.75rem;
    color: ${theme.palette.main.professional_blue};

    @media (min-width: ${MIN_WIDTH_MD}) {
      margin-top: 0.5rem;
      width: 320px;
      font-size: 0.875rem;
    }
  `
);

export const StyledVitoloCrossSellingCardButton = styled(Href)(
  ({ theme }) => css`
    position: relative;
    margin-top: 1rem;
    color: ${theme.palette.common.white};
    text-decoration: none;
    background-color: ${theme.palette.main.professional_blue};

    &::after {
      content: '';
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      &::after {
        content: '';
        background: url(${imgLinks['crossselling.arrow']}) no-repeat;
        background-size: 38px 49px;
        display: inline-block;
        position: absolute;
        right: -38px;
        top: 30%;
        width: 38px;
        height: 49px;
        -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
      }
    }
  `
);
