import { createTheme } from '@mui/material';

import { PALETTE } from './palette';
import { defaultTheme } from './default.theme';

// eslint-disable-next-line import/prefer-default-export
export const petoloTheme = createTheme(defaultTheme, {
  palette: {
    background: {
      button: PALETTE.VITALIZED_ORANGE,
      cancellationDate: PALETTE.VITALIZED_ORANGE,
      footer: PALETTE.CLEAR_LIGHT_ORANGE,
      info: PALETTE.CLEAR_LIGHT_ORANGE,
      radio: PALETTE.VITALIZED_ORANGE,
      tag: PALETTE.VITALIZED_ORANGE,
      viewport: PALETTE.ULTRA_LIGHT_GRAY,
    },
    shadows: [
      defaultTheme.shadows[0],
      defaultTheme.shadows[1],
      defaultTheme.shadows[2],
      `0px 0px 0px 3px ${PALETTE.PROFESSIONAL_LIGHT_BLUE}`,
      `0 0 4px 1px ${PALETTE.VITALIZED_ORANGE}`,
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    text: {
      button: PALETTE.PROFESSIONAL_BLUE,
      copyright: PALETTE.VITALIZED_ORANGE,
      disabled: PALETTE.GRAY,
      footerLink: PALETTE.PROFESSIONAL_BLUE,
      footerLinkHover: PALETTE.VITALIZED_ORANGE,
      subtext: PALETTE.PROFESSIONAL_LIGHT_BLUE,
    },
  },
});
